<template>
  <v-dialog
    width="700"
    persistent
    transition="slide-x-transition"
    v-model="get_modal_view_funcionario"
  >
    <v-card class="expande-horizontal wrap">
      <div class="expande-horizontal px-1 pr-3 py-3">
        <v-btn dark @click="fecha_modal_view_funcionario" icon>
          <v-icon color="grey">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span style="font-size: 17pt; width: 100%;" class="fonte">
          Registro de funcionario
        </span>
        <div class="expande-horizontal"></div>
        <v-btn class="elevation-3" icon @click="fecha_modal_view_funcionario">
          <v-icon color="red">
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <div class="expande-horizontal wrap py-6 pb-0">
        <v-flex xs12>
          <v-form ref="form">
            <ModalSendArchive />
            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Nome
              </span>
              <v-text-field
                v-model="get_funcionario.nome"
                dense
                solo
                flat
                outlined
                clearable
                :color="$theme.primary"
                :rules="[v => !!v || 'Preencha este campo']"
                label="ex: Roberto"
              ></v-text-field>
            </v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Perfil de Permissão
              </span>
              <v-select
                v-model="get_funcionario.permissao"
                dense
                solo
                flat
                return-object
                item-text="nome"
                :items="get_permissoes.docs"
                outlined
                :color="$theme.primary"
                :rules="[v => !!v || 'Preencha este campo']"
                label="ex: ADMIN"
              ></v-select>
            </v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Email
              </span>
              <v-text-field
                v-model="get_funcionario.email"
                dense
                solo
                flat
                outlined
                clearable
                :color="$theme.primary"
                :rules="[v => !!v || 'Preencha este campo']"
                label="ex: meuemail@gmail.com"
              ></v-text-field>
            </v-flex>

            <div class="expande-horizontal">
              <v-flex class="px-7" xs6>
                <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                  CPF
                </span>
                <v-text-field
                  v-model="get_funcionario.cpf"
                  dense
                  solo
                  flat
                  outlined
                  :rules="[v => !!v || 'Preencha este campo']"
                  v-mask="['###.###.###-##']"
                  clearable
                  :color="$theme.primary"
                  label="ex: 000.000.000-00"
                ></v-text-field>
              </v-flex>

              <v-flex class="px-7" xs6>
                <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                  RG
                </span>
                <v-text-field
                  v-model="get_funcionario.rg"
                  dense
                  solo
                  flat
                  outlined
                  :rules="[v => !!v || 'Preencha este campo']"
                  v-mask="['#########']"
                  clearable
                  :color="$theme.primary"
                  label="ex: 000.000"
                ></v-text-field>
              </v-flex>
            </div>

            <div class="expande-horizontal">
              <v-flex class="px-7" xs6>
                <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                  Senha
                </span>
                <v-text-field
                  v-model="get_funcionario.senha"
                  dense
                  solo
                  flat
                  type="password"
                  outlined
                  clearable
                  :color="$theme.primary"
                  label="******"
                ></v-text-field>
              </v-flex>

              <v-flex class="px-7" xs6>
                <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                  Telefone
                </span>
                <v-text-field
                  v-model="get_funcionario.phone"
                  dense
                  solo
                  flat
                  outlined
                  :rules="[v => !!v || 'Preencha este campo']"
                  v-mask="['(##) # #### ####']"
                  clearable
                  :color="$theme.primary"
                  label="ex: (96) 9 8419-6827"
                ></v-text-field>
              </v-flex>
            </div>
            <v-flex class="px-7" xs6>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Nascimento
              </span>
              <v-text-field
                v-model="get_funcionario.data_nascimento"
                dense
                solo
                flat
                outlined
                :rules="[v => !!v || 'Preencha este campo']"
                v-mask="['##/##/####']"
                clearable
                :color="$theme.primary"
                label="ex: 12/12/1984"
              ></v-text-field>
            </v-flex>
          </v-form>
        </v-flex>
      </div>

      <div class="expande-horizontal">
        <v-btn large dark @click="valida_form" tile block color="green">
          <span class="fonte"> Salvar </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
export default {
  components: { ModalSendArchive },
  computed: {
    ...mapGetters([
      "get_funcionario",
      "get_permissoes",
      "get_permissoes_filtros",
      "get_modal_view_funcionario",
      "getLoggedUser"
    ])
  },
  methods: {
    ...mapActions([
      "criar_funcionario",
      "listar_permissoes",
      "atualizar_funcionario",
      "listarProfessores",
      "fecha_modal_view_funcionario"
    ]),
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_funcionario._id
          ? this.atualizar_funcionario()
          : this.criar_funcionario();
      }
    }
  },
  created() {
    this.listar_permissoes_filtros.all = true;
    this.listar_permissoes();
  }
};
</script>
